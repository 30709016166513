import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'

export function getTimeStops(start, end, intervals = 30) {
  if(start.length === 5) start = start + ':00'
  const startTime = moment.utc(start, 'HH:mm:ss')
  if(end.length === 5) end = end + ':00'
  const endTime = moment.utc(end, 'HH:mm:ss')

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }

  const timeStops = []

  while (startTime <= endTime) {
    timeStops.push(new moment.utc(startTime).format('HH:mm'))
    startTime.add(intervals, 'minutes')
  }
  return timeStops
}

export const getResources = (dailyFreeTimes, selectedvenue, appconfig) => {
  return dailyFreeTimes
    .map((slot) => {
      return slot.courts
        .filter((x) => {
          if (appconfig.categories.length > 0 && selectedvenue !== 'all') {
            return x.category === selectedvenue
          } else {
            return true
          }
        })
        .map((x) => ({
          venue: x.venue,
          id: x.id,
          courtname: x.name || x.displayName,
          category: x.category,
          order: x.order,
        }))
    })
    .flat(2)
    .reduce((acc, cur) => {
      const f = acc.find((x) => x.id === cur.id)
      if (!f) acc.push(cur)
      return acc
    }, [])
}

export const getResourceTimes = (dailyFreeTimes, selectedvenue, appconfig) => {
  return dailyFreeTimes.reduce((acc, slot) => {
    for (let court of slot.courts.filter((x) => {
      if (appconfig.categories.length > 0 && selectedvenue !== 'all') {
        return x.category === selectedvenue
      } else {
        return true
      }
    })) {
      court.start = slot.start
      court.startmoment = moment.utc(court.start)
      if (!acc[court.id]) acc[court.id] = []
      acc[court.id].push(court)
    }
    return acc
  }, {})
}

export const formatSlot = (slot, duration) => {
  let t = {}
  if (duration) {
    t = slot.times.find((t) => t.duration === duration)
  } else {
    t = slot.times[0]
  }
  const s = JSON.parse(JSON.stringify(slot))
  delete s.times
  return { ...s, ...t }
}

// const uiBreaks = {
//   1200: 'desktop',
//   992: 'tablet',
//   768: 'mobile',
// }

export const formatResourceName = (name, width, amount, orientation) => {
  // let _break = 0
  // if (width > 1199) {
  //   _break = 3 // desktop
  // } else if (width > 991 && width < 1200) {
  //   _break = 2
  // } else if (width > 767 && width < 992) {
  //   _break = 1
  // }
  if (!name) return null
  const wc = name.split(' ').length
  if (wc > 1) return name
  if (amount < 10) return name
  if (orientation === 'vertical') {
    if (name.length < 7) return name
    let _s = name.substring(0, 2) + name.replace(/[^0-9]+/, '')
    if (_s.length > 7) return _s.split(' ')[0]
    return _s
  }
  return name
}

export const getResourceRow = (resource, times, resourceTimes, reservations) => {
  if(!times) return []
  if (!resourceTimes) return times.map((t) => ({ t, isStart: false, isVacancy: false }))
  return times.map((t) => {
    const _t = moment.utc(t, 'HH:mm')
    if (resourceTimes[resource.id] && resourceTimes[resource.id].length > 0) {
      const dateSetter = resourceTimes[resource.id][0].startmoment
      _t.year(dateSetter.year()).month(dateSetter.month()).date(dateSetter.date())
    }
    const isStart = resourceTimes[resource.id] ? resourceTimes[resource.id].find((r) => t === r.startmoment.format('HH:mm')) : false
    const isVacancy = resourceTimes[resource.id] ? (resourceTimes[resource.id].find((r) => {
      if (r.times && r.times.length > 0) {
        const duration = r.times[r.times.length - 1].duration
        const _s = moment.utc(r.startmoment)
        return _t.isBetween(_s, moment.utc(r.startmoment).add(duration, 'minutes'), null, '[)')
      } else {
        return false
      }
    })
      ? true
      : false) : false
    let isOwnDuration = false
    let isOwn = false
    if (reservations) {
      const _r = reservations.find((r) => {
        if(r.resourceId !== resource.id) return false
        const _s = moment.utc(r.start)
        const _e = moment.utc(r.end)
        return _t.isBetween(_s, _e, null, '()')
      })
      if (_r) {
        isOwn = true
        isOwnDuration = _t.isBetween(moment.utc(_r.start), moment.utc(_r.end), null, '[)')
      }
    }
    return { t, isStart, isVacancy, isOwn, isOwnDuration }
  })
}


const __getWallet = (appconfig, category) => { 
  let wallet = 'default'
  if (appconfig.accountkey.startsWith('tali-')) {
    wallet = 'tali'
    if (category && category.indexOf('taivallahti') > -1) {
      wallet = 'taivallahti'
    }
  }
  if (appconfig.accountkey.startsWith('evs-')) {
    wallet = 'aktia'
    if (category && category.indexOf('martinmäki') > -1) {
      wallet = 'martinmäki'
    }
  }
  if (appconfig.accountkey.startsWith('roimailapelikeskus-')) {
    wallet = 'roimailapelikeskus'
    if (category && category.indexOf('ulko') > -1) {
      wallet = 'rovs'
    }
  }
  if (appconfig.accountkey.startsWith('lahdentennisjasquash-')) {
    wallet = 'default'
    if (category && category.indexOf('ulkotennis') > -1) {
      wallet = 'ulkotennis'
    }
  }
  return wallet
}

export const getWallet = (appconfig, summary) => {
  let wallet = 'default'
  if (Array.isArray(summary)) {
    const hasSummaryCategory = summary.filter((item) => item.category)
    const hasActivities = summary.find((item) => item.collection === 'activities')
    if (hasSummaryCategory.length > 0) {
      return __getWallet(appconfig, hasSummaryCategory[0].category)
    } else if (!hasActivities) { 
      const _cat = summary.map((item) => item.resourcegroups[0].resources[0].category).filter((x) => x)
      if (_cat.length > 0) {
        return __getWallet(appconfig, _cat[0])
      }
    }
  } else {
    if (summary.category) {
      return __getWallet(appconfig, summary.category)
    } else if (summary.collection === 'activities') {
      return __getWallet(appconfig, 'activities')
    }
  }
  if (summary.invoicer) {
    wallet = summary.invoicer.toLowerCase()
  }
  return wallet
}

export const getStorage = (appconfig, summary) => {
  let storage = 'default'
  let category = null
  console.log('getStorage', appconfig, summary)
  if (appconfig.accountkey.startsWith('lahdentennisjasquash-')) {
    if (Array.isArray(summary)) {
      const _cat = summary.map((item) => item.resourcegroups[0].resources[0].category).filter((x) => x)
      if (_cat.length > 0) {
        category = _cat[0]
      }
    } else {
      if (summary.category) {
        category = summary.category
      }
    }
    if (category && category.indexOf('ulkotennis') > -1) {
      storage = 'ulkotennis'
    }
  } 
  return storage
}

export const getPosterUrl = (data, preferredSize) => {
  if (!data) {
    return null
  }
  if (!preferredSize) {
    preferredSize = 600
  }
  let _optimizedPoster = null
  if (typeof data === 'object') {
    const { url, sizes } = data
    const closestSize = sizes.reduce((prev, curr) => {
      return Math.abs(curr - preferredSize) < Math.abs(prev - preferredSize) ? curr : prev
    })
    if (url.indexOf('.svg') > -1) {
      _optimizedPoster = url
    } else {
      _optimizedPoster = url
        ? url
          .split('/')
          .splice(0, url.split('/').length - 1)
          .join('/') +
        '/' +
        closestSize +
        '_' +
        url.split('/').splice(-1)
        : ''
    }
  } else if (typeof data === 'string') {
    if (data.indexOf('.svg') > -1) {
      _optimizedPoster = data
    } else {
      _optimizedPoster =
        data
          .split('/')
          .splice(0, data.split('/').length - 1)
          .join('/') +
        '/' +
        preferredSize +
        '_' +
        data.split('/').splice(-1)
    }
  }
  if (_optimizedPoster) {
    return _optimizedPoster
  } else {
    return null
  }
}

const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
  return (tzDate.getTime() - utcDate.getTime()) / 6e4
}

const TIMEZONEOFFSET = getOffset('Europe/Helsinki')

export const formatVacancies = (vacancies, dateindex, resources, all) => {
  // console.log('formatVacancies', dateindex, resources, vacancies)
  const byStartTime = {}
  for (let resourceid in resources) {
    const resource = JSON.parse(JSON.stringify(resources[resourceid]))
    resource.id = resourceid
    if (vacancies[resource.id]) {
      vacancies[resource.id].forEach((v, i) => {
        if (!byStartTime[v.s])
          byStartTime[v.s] = {
            courts: [],
            start: moment.utc(dateindex + 'T' + v.s, 'YYYYMMDDTHHmm').format(),
            durations: [],
          }
        const court = {
          ...resource,
          id: resource.id,
          start: moment.utc(dateindex + 'T' + v.s, 'YYYYMMDDTHHmm').format(),
        }
        let times = []
        if (v.ex) {
          times = v.ex.map((x) => {
            byStartTime[v.s].durations.push(x.du)
            return { duration: x.du, price: x.p / 100, resourceId: resource.id }
          })
          court.times = times
        }
        if (v.e === '2359') {
          const ovdu = moment(v.e, 'HHmm').diff(moment(v.s, 'HHmm'), 'minutes')
          const vdu = Math.ceil(moment(v.e, 'HHmm').endOf('day').diff(moment(v.s, 'HHmm'), 'seconds') / 60)
          byStartTime[v.s].durations.push(vdu)
          times.push({ duration: vdu, od: ovdu, price: v.p / 100, resourceId: resource.id })
        } else {
          const vdu = moment(v.e, 'HHmm').diff(moment(v.s, 'HHmm'), 'minutes')
          byStartTime[v.s].durations.push(vdu)
          times.push({ duration: vdu, price: v.p / 100, resourceId: resource.id })
        }
        court.times = times.sort((a, b) => a.duration - b.duration)

        const uniqueDurations = [...new Set(byStartTime[v.s].durations)].sort((a, b) => a - b)
        byStartTime[v.s].durations = uniqueDurations
        byStartTime[v.s].courts.push(court)
      })
    } else {
      const court = { ...resource, id: resource.id, times: [] }
      if (!byStartTime['0000'])
        byStartTime['0000'] = { courts: [], start: moment.utc(dateindex + 'T0000').format(), durations: [] }
      byStartTime['0000'].courts.push(court)
    }
  }
  const byStartTimeArray = Object.values(byStartTime).filter((x) => moment.utc(x.start))
  byStartTimeArray.sort((a, b) => moment.utc(a.start).unix() - moment.utc(b.start).unix())
  return byStartTimeArray
    .filter((x) => { if (all) { return true } else { return moment.utc(x.start).isAfter(moment.utc().add(TIMEZONEOFFSET, 'minutes'), 'minutes') } })
    .map((x, i) => {
      return { ...x, id: dateindex, key: dateindex + '.' + i }
    })
}